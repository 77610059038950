import axios from "axios";

import ls from "localstorage-slim";

import encUTF8 from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";

ls.config.encrypt = true;
ls.config.secret = "1385";
let backendUrl;

if (process.env.NODE_ENV === "production") {
  backendUrl = "https://api.hkajet.com";
} else {
  backendUrl = "http://127.0.0.1:8000";
}

ls.config.encrypter = (data, secret) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(JSON.stringify(data));
  }
  try {
    return AES.encrypt(JSON.stringify(data), secret).toString();
  } catch (e) {
    console.error(e);
  }
};
ls.config.decrypter = (data, secret) => {
  try {
    return JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
  } catch (e) {
    console.error(e);
  }
};

// https://api.hkajet.com /PUBLIC URL
// https://crypto-trading--backend.herokuapp.com /PUBLIC URL
// http://127.0.0.1:8000 / LOCAL URL
const api = axios.create({
  baseURL: backendUrl,
  headers: {
    Authorization: ls.get("access_token", { secret: ls.config.secret })
      ? "JWT " + ls.get("access_token", { secret: ls.config.secret })
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export default api;
export { ls, backendUrl };
