const mutations = {
  setUser: (state, user) => {
    state.user = user;
  },
  logout: (state) => {
    state.user = {};
  },
  setLogs: (state, logs) => {
    state.logs = logs;
  },
  logged: (state) => {
    state.logged = true;
  },
};

export default mutations;
