<template>
  <li class="nav-item d-flex w-100 justify-content-between align-items-center">
    <router-link class="nav-link w-100" :to="link" active-class="active">
      <i :class="icon"></i>
      {{ title }}
    </router-link>
    <router-link v-if="create_icon" :to="create_link">
      <i class="p-3 bi bi-plus-circle-dotted"></i>
    </router-link>
  </li>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "EssentialLink",
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    create_icon: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "#",
    },
    create_link: {
      type: String,
      default: "#",
    },
  },
});
</script>

<style lang="scss" scoped>
a {
  color: #333;
  &.active {
    color: #2470dc;
  }
}
</style>
