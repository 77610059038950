import repository from "../../../services/repository.service";
import router from "../../../router";
import toast from "../../../services/notification.service";
import { backendUrl } from "../../../utils/axios";
const actions = {
  getBots: ({ commit }, { url }) => {
    repository
      .getAll(url)
      .then((res) => {
        commit("setBots", res);
      })
      .catch((err) => err);
  },
  getBot: async ({ commit }, { pk }) => {
    await repository
      .getById(`/macd_bot/${pk}/`)
      .then((res) => {
        commit("setBot", res["bot"]);
        commit("setSignals", res["signals"]);
        commit("setTransactions", res["transactions"]);
        commit("setAlerts", res["alerts"]);
        commit("setWalletForBot", res["wallet_for_bot"]);
        commit("setWalletsForTransaction", res["wallet_for_transaction"]);
      })
      .catch((err) => err);
  },
  createBot: async ({ commit, dispatch }, { data }) => {
    await repository
      .Create("/macd_bot/", data)
      .then((res) => {
        commit("setBot", { data: res });
        toast.success("Bot başarıyla oluşturuldu");
        dispatch(
          "account/createLog",
          {
            action: `${res["bot_name"]} isimli yeni bir Macd Bot oluşturuldu`,
          },
          { root: true }
        );
        router.push(`/macd_bots/${res["id"]}`);
      })
      .catch((err) => err);
  },
  startBot: async ({ commit }, { pk }) => {
    await repository
      .getById(`/macd_bot/${pk}/start/`)
      .then(() => commit("startBot"))
      .catch((err) => console.error(err));
  },
  stopBot: async ({ commit }, { pk }) => {
    await repository
      .getById(`/macd_bot/${pk}/stop/`)
      .then(() => commit("stopBot"))
      .catch((err) => console.error(err));
  },
  deleteBot: ({ commit, dispatch }, { pk }) => {
    repository
      .Delete(`/macd_bot/${pk}/`)
      .then((res) => {
        commit("deleteBot", pk);
        toast.success("Bot başarıyla silindi");
        dispatch(
          "account/createLog",
          {
            action: `${res} isimli Macd Bot silindi`,
          },
          { root: true }
        );
        router.push("/macd_bots");
      })
      .catch((err) => console.error(err));
  },
  exportTransactions: async ({ commit }, { pk }) => {
    commit("transactionExportLoading", true);
    await repository
      .getById(`/macd_bot/${pk}/export_transactions/`)
      .then((url) => {
        commit("transactionExportLoading", false);
        window.location.href = `${backendUrl}/${url}/`;
      })
      .catch((err) => {
        commit("transactionExportLoading", false);
        console.log(err);
      });
  },
  getLogs: async ({ commit }, { pk }) => {
    await repository
      .getById(`/macd_bot/${pk}/logs/`)
      .then((res) => {
        commit("setLogs", res);
      })
      .catch((err) => err);
  },
};

export default actions;
