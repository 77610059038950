<template>
  <DashboardLayout>
    <div class="home">This is an home page</div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/Dashboard.layout";
export default {
  name: "Home",
  components: {
    DashboardLayout,
  },
};
</script>
