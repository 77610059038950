import userService from "../../../services/user.service";
import repository from "../../../services/repository.service";
import router from "../../../router";
import toast from "../../../services/notification.service";

const actions = {
  login: ({ commit, dispatch }, { user }) => {
    userService
      .login(user)
      .then(() => userService.getUser())
      .then((user) => {
        commit("setUser", user);
        toast.success("Giriş başarıyla yapıldı");
        dispatch("createLog", { action: "Sisteme giriş yapıldı." });
        router.push("/d");
      })
      .catch((err) => {
        console.error(err);
        return toast.error("Kullanıcı adı veya parola hatalı!");
      });
  },
  register: ({ commit }, { user }) => {
    commit("logout");
    userService
      .register(user)
      .then(() => {
        toast.success("Kayıt başarıyla oluşturuldu.");
        router.push("/login");
      })
      .catch((err) => {
        console.error(err);
        return toast.error("Kayıt oluşturulamadı!");
      });
  },
  logout: ({ commit, dispatch }) => {
    dispatch("createLog", { action: "Sistemden çıkış yapıldı" });
    userService
      .logout()
      .then(() => {
        commit("logout");
        toast.success("Güvenli bir şekilde çıkış yapıldı.");
        router.push("/login");
      })
      .catch((err) => err);
  },
  getUser: ({ commit }) => {
    userService
      .getUser()
      .then((user) => commit("setUser", user))
      .catch((err) => err);
  },
  getLogs: ({ commit }, { url }) => {
    repository
      .getAll(url)
      .then((logs) => {
        commit("setLogs", logs);
      })
      .catch((err) => err);
  },
  createLog: ({ commit }, log) => {
    repository
      .Create("/account/log/", log)
      .then(() => commit("logged"))
      .catch((err) => err);
  },
};

export default actions;
