<template>
  <header
    class="
      navbar navbar-dark navbar-expand
      sticky-top
      bg-dark
      flex-md-nowrap
      p-0
      shadow
    "
  >
    <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-5 fw-light" href="#"
      ><img
        class="img-fluid me-1"
        width="24"
        src="/img/icons/favicon-32x32.png"
        alt="Logo"
        sizes="32x32"
      />
      HKAJET</a
    >
    <div
      class="
        collapse
        navbar-collapse
        flex-grow-1
        d-flex
        justify-content-end
        flex-row
        me-3
      "
      id="navbarNavDarkDropdown"
    >
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle d-flex align-items-center"
            href="#"
            id="navbarDarkDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>{{ user.username }}</span>
            <i class="bi bi-person-circle h2 mb-0 mx-2"></i>
          </a>
          <ul
            class="dropdown-menu dropdown-menu-dark dropdown-menu-end"
            aria-labelledby="navbarDarkDropdownMenuLink"
          >
            <li>
              <a class="dropdown-item" href="#"
                ><i class="bi bi-person-badge me-1"></i> Profil</a
              >
            </li>
            <!-- <li><hr class="dropdown-divider"></li> -->
            <li>
              <a class="dropdown-item" href="#" @click="logoutUser"
                ><i class="bi bi-box-arrow-right me-1"></i> Çıkış</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <button
      class="navbar-toggler d-md-none d-block collapsed me-3"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#sidebarMenu"
      aria-controls="sidebarMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
  </header>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Header",
  methods: {
    ...mapActions("account", ["logout"]),
    logoutUser() {
      this.logout();
    },
  },
  computed: {
    ...mapState("account", { user: "user" }),
  },
};
</script>

<style lang="scss" scoped>
.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar {
  .navbar-toggler {
    top: 0.25rem;
    right: 1rem;
  }
  .form-control {
    padding: 0.75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
}
.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
  }
}
</style>
