import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { ls } from "../utils/axios";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authPages = ["/login"];
  const authRequired = !authPages.includes(to.path);
  const loggedIn = ls.get("access_token", { secret: "1385" });

  if (authRequired && !loggedIn) {
    return next("/login");
  }
  if (!authRequired && loggedIn) {
    return next("/d");
  }
  next();
});

export default router;
