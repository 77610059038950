import Index from "../views/Dashboard/Index.vue";

const routes = [
  { path: "/", redirect: "/d" },
  {
    path: "/d",
    name: "Dashboard",
    component: Index,
  },
  {
    path: "/logs",
    name: "Logs",
    component: () => import("../views/Dashboard/Logs.vue"),
  },
  {
    path: "/exchange",
    name: "Exchange",
    component: () => import("../views/Dashboard/Exchange.vue"),
  },
  {
    path: "/price-signals",
    name: "PriceSignals",
    component: () => import("../views/Dashboard/Signals.vue"),
  },
  // Auth Pages

  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Auth/Register.vue"),
  },
  // Bot Pages
  {
    path: "/macd_bots",
    name: "Macd Bots",
    component: () => import("../views/Dashboard/Bots/Macd/List.vue"),
  },
  {
    path: "/macd_bots/create",
    name: "Macd Bot Create",
    component: () => import("../views/Dashboard/Bots/Macd/Create.vue"),
  },
  {
    path: "/macd_bots/:id",
    name: "Macd Bot Details",
    component: () => import("../views/Dashboard/Bots/Macd/Details.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    component: () => import("../views/Error/404.vue"),
  },
];

export default routes;
