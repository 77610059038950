import { createStore } from "vuex";
import account from "./modules/account";
import exchange from "./modules/exchange";
import macd_bots from "./modules/macd_bots";

export default createStore({
  modules: {
    account,
    exchange,
    macd_bots,
  },
});
