const getters = {
  getBots(state) {
    state.bots.sort(function (x, y) {
      return new Date(y.create_time) - new Date(x.create_time);
    });
    return state.bots.filter((bot) => bot.status !== "deleted");
  },
  getBot(state) {
    return state.bot;
  },
  getSignals(state) {
    return state.signals;
  },
  getSignal: (state) => (id) => {
    return state.signals.find((signal) => signal.bot === id);
  },
  getTransactions(state) {
    return state.transactions.sort(function (x, y) {
      return new Date(y.create_time) - new Date(x.create_time);
    });
  },
  getTransaction: (state) => (id) => {
    return state.transactions.find((transaction) => transaction.signal === id);
  },
  getAlerts(state) {
    return state.alerts;
  },
  getAlert: (state) => (id) => {
    return state.alerts.find((alert) => alert.transaction === id);
  },
  getWalletForBot(state) {
    return state.walletForBot;
  },
  getWalletsForTransaction(state) {
    return state.walletsForTransaction;
  },
  getWalletForTransaction: (state) => (id) => {
    return state.walletsForTransaction.find(
      (wallet) => wallet.transaction === id
    );
  },
  getLogs(state) {
    return state.logs;
  },
};

export default getters;
