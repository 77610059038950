import api from "../utils/axios";

class repository {
  static async getAll(url) {
    return await api
      .get(url)
      .then((res) => res.data)
      .catch((err) => err);
  }
  static async getById(url) {
    return await api
      .get(url)
      .then((res) => res.data)
      .catch((err) => err);
  }

  static async Create(url, data) {
    return await api
      .post(url, data)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  static async Update(url, data) {
    return await api
      .put(url, data)
      .then((res) => res.data)
      .catch((err) => err);
  }
  static async Delete(url, data = null) {
    return await api
      .delete(url, { data: data })
      .then((res) => console.log(res.data))
      .catch((err) => console.error(err));
  }
}

export default repository;
