const mutations = {
  setExchange: (state, data) => {
    state.exchanges.push(data);
  },
  setWallet: (state, data) => {
    state.wallet = data;
  },
  getExchange: (state, data) => {
    state.exchange = data;
  },
  getExchanges: (state, data) => {
    state.exchanges = data;
  },
  connectExchange: (state) => {
    state.connected = true;
  },
  submitedOrder: (state) => {
    state.submitedOrder = true;
  },
  setPriceSignal: (state, data) => {
    state.activePriceSignals.push(data);
  },
  getActivePriceSignals: (state, data) => {
    state.activePriceSignals = data.filter((signal) => {
      return signal.status === "active";
    });
  },
  getDeactivePriceSignals: (state, data) => {
    state.deactivePriceSignals = data.filter((signal) => {
      return signal.status !== "active";
    });
  },
};

export default mutations;
