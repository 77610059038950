const state = {
  bots: [],
  pendingBots: true,

  bot: [],
  pendingBot: true,
  createLoading: false,
  macdBotStartLoading: false,
  macdBotStopLoading: false,
  macdBotTransactionExportLoading: false,

  logs: [],

  signals: [],

  transactions: [],

  alerts: [],

  walletForBot: [],

  walletsForTransaction: [],
};

export default state;

// {
//   id: 1,
//   bot_name: "Macd Bot 1",
//   start_date: Date("2021-09-05T16:00.00"),
//   end_date: "",
//   limitless: true,
//   exchange: "bitfinex",
//   account_name: "Primary",
//   pair: "BTC/USD",
//   status: "completed",
//   case_at_starting: {
//     Exchange: { Usd: 150, Btc: 0.0201 },
//     Margin: { Usd: 70, Btc: 0 },
//   },
//   case_at_finishing: {
//     Exchange: { Usd: 160, Btc: 0.0231 },
//     Margin: { Usd: 70, Btc: 0 },
//   },
//   profit_and_loss: "+10$",
//   signals: [
//     {
//       signal_no: 1,
//       token: "xxxxxxx",
//       transaction_table: "exchange",
//       case_ratio: "50",
//       order_type: "LIMIT",
//       transactions: [
//         {
//           transaction_id: 1,
//           position: "long",
//           status: "executed",
//           case_at_starting: {
//             Exchange: { Usd: 150, Btc: 0.0201 },
//             Margin: { Usd: 70, Btc: 0 },
//           },
//           case_at_finishing: {
//             Exchange: { Usd: 160, Btc: 0.0231 },
//             Margin: { Usd: 70, Btc: 0 },
//           },
//           start_date: Date("2021-09-05T16:00:00"),
//           trigger_date: Date("2021-09-05T16:10:00"),
//           alerts: [
//             {
//               alert_id: 1,
//               status: "active",
//               transaction_amount: 75,
//             },
//             {
//               alert_id: 2,
//               status: "executed",
//               action_point: "48000",
//             },
//           ],
//         },
//       ],
//     },
//     {
//       signal_no: 2,
//       token: "xyxyxxy",
//       transaction_table: "exchange",
//       case_ratio: "75",
//       order_type: "SCALED",
//       //Scaled amount kadar işlem yapılmadıysa kalan kadar limit order aç ve eldekileri bitir
//       end_point_type: "percent",
//       end_point: "10",
//       transactions: [
//         {
//           transaction_id: 2,
//           position: "short",
//           status: "waiting",
//           alerts: [
//             {
//               alert_id: 3,
//               start_date: Date("2021-09-05T16:10:01"),
//               status: "short",
//               transaction_amount: 112.5,
//               order_count: 10,
//             },
//             {
//               alert_id: 4,
//               trigger_date: Date("2021-09-05T16:13:00"),
//               status: "executed",
//               action_point: "48000",
//               end_point: "52800",
//               average: "50400",
//               amount_of_transactions: 105.45,
//             },
//           ],
//         },
//       ],
//     },
//   ],
//   transaction_pal: [
//     {
//       pal_id: 1,
//       long_transaction_id: 1,
//       short_transaction_id: 2,
//       case_at_starting: 150,
//       case_at_finishing: 160,
//       profit_and_loss: 10,
//       create_at_date: Date("2021-09-05T16:00.00"),
//       finish_at_date: Date("2021-09-05T16:13.00"),
//       status: "completed",
//     },
//   ],
// },
// {
//   id: 2,
//   bot_name: "Macd Bot 2",
//   start_date: Date("2021-09-06T13:00:00"),
//   end_date: "",
//   limitless: true,
//   exchange: "bitfinex",
//   account_name: "Primary",
//   pair: "BTC/USD",
//   status: "active",
//   case_at_starting: {
//     Exchange: { Usd: 150, Btc: 0.0201 },
//     Margin: { Usd: 70, Btc: 0 },
//   },
//   case_at_finishing: {
//     Exchange: { Usd: 160, Btc: 0.0231 },
//     Margin: { Usd: 70, Btc: 0 },
//   },
//   profit_and_loss: "+10$",
//   signals: [
//     {
//       signal_no: 3,
//       token: "xzxzxzx",
//       transaction_table: "exchange",
//       case_ratio: "50",
//       order_type: "LIMIT",
//       transactions: [
//         {
//           transaction_id: 3,
//           position: "long",
//           status: "executed",
//           start_date: Date("2021-09-05T16:00:00"),
//           trigger_date: Date("2021-09-05T16:10:00"),
//           alerts: [
//             {
//               alert_id: 5,
//               status: "active",
//               transaction_amount: 75,
//             },
//             {
//               alert_id: 6,
//               status: "executed",
//               action_point: "48000",
//               amount_of_transactions: 74.64,
//             },
//           ],
//         },
//       ],
//     },
//     {
//       signal_no: 4,
//       token: "yxyxxyx",
//       transaction_table: "exchange",
//       case_ratio: "75",
//       order_type: "SCALED",
//       end_point_type: "percent",
//       end_point: "10",
//       transactions: [
//         {
//           transaction_id: 4,
//           position: "short",
//           status: "pending",
//           alerts: [
//             {
//               alert_id: 7,
//               start_date: Date("2021-09-05T16:10:01"),
//               status: "short",
//               transaction_amount: 112.5,
//               order_count: 10,
//             },
//             {
//               alert_id: 8,
//               trigger_date: Date("2021-09-05T16:13:00"),
//               status: "executed",
//               action_point: "48000",
//               end_point: "52800",
//               average: "50400",
//               amount_of_transactions: 112.47,
//             },
//           ],
//         },
//       ],
//     },
//   ],
//   transaction_pal: [
//     {
//       pal_id: 1,
//       long_transaction_id: 1,
//       short_transaction_id: 2,
//       case_at_starting: 150,
//       case_at_finishing: 160,
//       profit_and_loss: 10,
//       create_at_date: Date("2021-09-05T16:00.00"),
//       finish_at_date: Date("2021-09-05T16:13.00"),
//       status: "completed",
//     },
//   ],
// },
