<template>
  <nav
    id="sidebarMenu"
    class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
  >
    <div class="position-sticky pt-3">
      <ul class="nav flex-column">
        <EssentialLink
          v-for="link in mainLinks"
          :key="link.title"
          v-bind="link"
        ></EssentialLink>
      </ul>

      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-4
          mb-1
          text-muted
        "
      >
        <i class="bi bi-stopwatch me-2"></i>
        <span class="flex-grow-1">Signals</span>
      </h6>
      <ul class="nav flex-column mb-2 ms-3">
        <EssentialLink
          v-for="link in signalLinks"
          :key="link.title"
          v-bind="link"
        ></EssentialLink>
      </ul>
      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-4
          mb-1
          text-muted
        "
      >
        <i class="bi bi-signpost-split me-2"></i>
        <span class="flex-grow-1">Botlar</span>
      </h6>
      <ul class="nav flex-column mb-2 ms-3">
        <EssentialLink
          v-for="link in botLinks"
          :key="link.title"
          v-bind="link"
        ></EssentialLink>
      </ul>
    </div>
  </nav>
</template>

<script>
import EssentialLink from "../../EssentialLink.vue";

const mainLinksList = [
  {
    title: "Dashboard",
    icon: "bi bi-window-sidebar",
    link: "/d",
  },
  { title: "İşlem Kayıtları", icon: "bi bi-journal-text", link: "/logs" },
  { title: "İşlemler", icon: "bi bi-currency-exchange", link: "/exchange" },
];

const signalLinksList = [{ title: "Price Signal", link: "/price-signals" }];
const botLinksList = [
  {
    title: "MACD BOT",
    link: "/macd_bots",
    create_icon: true,
    create_link: "/macd_bots/create",
  },
];

export default {
  name: "Sidebar",
  components: { EssentialLink },
  data() {
    return {
      mainLinks: mainLinksList,
      signalLinks: signalLinksList,
      botLinks: botLinksList,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

  .nav-link {
    font-weight: 500;
    color: #333;

    .feather {
      margin-right: 4px;
      color: #727272;
    }
    &.active {
      color: #2470dc;

      .feather {
        color: inherit;
      }
    }
    &:hover {
      .feather {
        color: inherit;
      }
    }
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
</style>
