import api from "../utils/axios";
import { ls } from "../utils/axios";

class userService {
  static async login(user) {
    try {
      const res = await api.post("/account/login/", user);
      ls.set("access_token", res.data.access, {
        ttl: 3600, // 1-hour time to live
      });
      ls.set("refresh_token", res.data.refresh, {
        ttl: 10800, // 3-hour time to live
      });
      api.defaults.headers["Authorization"] =
        "JWT " + ls.get("access_token", { secret: ls.config.secret });
    } catch (err) {
      Promise.reject(err);
    }
  }

  static async register(user) {
    try {
      const res = await api.post("/account/register/", user);
      return await Promise.resolve(res);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  static async logout() {
    try {
      const res = await api.post("/account/logout/", {
        refresh_token: ls.get("refresh_token", { secret: "1385" }),
      });
      ls.remove("access_token");
      ls.remove("refresh_token");
      api.defaults.headers["Authorization"] = null;
      return await Promise.resolve(res);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  static async getUser() {
    try {
      const user = await api.get("/account/profile/");
      return await Promise.resolve(user.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }
}

export default userService;
