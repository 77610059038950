const mutations = {
  setBots: (state, data) => {
    state.bots = data;
    state.pendingBots = false;
  },
  createLoading: (state) => {
    state.createLoading = true;
  },
  setBot: (state, data) => {
    state.createLoading = false;
    state.bot = data;
    state.pendingBot = false;
  },
  startLoading: (state) => {
    state.macdBotStartLoading = true;
  },
  startBot: (state) => {
    state.macdBotStartLoading = false;
    state.bot.status = "active";
  },
  stopLoading: (state) => {
    state.macdBotStopLoading = true;
  },
  stopBot: (state) => {
    state.macdBotStopLoading = false;
    state.bot.status = "stopped";
  },
  deleteBot: (state, id) => {
    const macd_bot =
      state.bots.length !== 0
        ? state.bots.filter((bot) => bot.id === id)
        : state.bot;
    macd_bot.status = "deleted";
  },
  setSignals: (state, data) => {
    state.signals = data;
  },
  setTransactions: (state, data) => {
    state.transactions = data;
  },
  transactionExportLoading: (state, status) => {
    state.macdBotTransactionExportLoading = status;
  },
  setAlerts: (state, data) => {
    state.alerts = data;
  },
  setWalletForBot: (state, data) => {
    state.walletForBot = data;
  },
  setWalletsForTransaction: (state, data) => {
    state.walletsForTransaction = data;
  },
  setLogs: (state, data) => {
    state.logs = data;
  },
  resetBot: (state) => {
    state.bot = [];
    state.logs = [];
    state.signals = [{}, {}];
    state.transactions = [];
    state.alerts = [];
    state.walletForBot = [];
    state.walletsForTransaction = [];
  },
};

export default mutations;
