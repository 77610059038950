const state = {
  exchanges: [],
  exchange: {},
  wallet: {},
  activePriceSignals: [],
  deactivePriceSignals: [],
  connected: false,
  submitedOrder: false,
};

export default state;
