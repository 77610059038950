<template>
  <div>
    <Header></Header>
    <div class="container-fluid">
      <div class="row">
        <Sidebar></Sidebar>
        <main class="col-md-9 ms-sm-auto pt-3 col-lg-10 px-md-4 py-md-4">
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Dashboard/Shared/Header";
import Sidebar from "@/components/Dashboard/Shared/Sidebar";

import { mapActions } from "vuex";

import moment from "moment";

export default {
  name: "dashboard",
  components: {
    Header,
    Sidebar,
  },
  created() {
    this.getUser();
    moment.locale("TR");
  },
  methods: { ...mapActions("account", ["getUser"]) },
};
</script>

<style lang="scss" scoped></style>
