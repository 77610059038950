import repository from "../../../services/repository.service";
import toast from "../../../services/notification.service";

const actions = {
  createExchange: ({ commit }, { data }) => {
    repository
      .Create("/exchange/", data)
      .then((res) => commit("setExchange", res))
      .catch((err) => err);
  },
  getExchange: ({ commit }, { pk }) => {
    repository
      .getById(`/exchange/${pk}`)
      .then((res) => commit("getExchange", res))
      .then((err) => err);
  },
  getExchanges: ({ commit }, { url }) => {
    repository
      .getAll(url)
      .then((res) => {
        commit("getExchanges", res);
      })
      .then((err) => err);
  },
  updateExchange: ({ dispatch }, { pk, data }) => {
    repository
      .Update(`/exchange/${pk}/`, data)
      .then(() => dispatch("getExchanges"))
      .catch((err) => err);
  },
  deleteExchange: ({ dispatch }, { pk }) => {
    repository
      .Delete(`/exchange/${pk}`)
      .then(() => dispatch("getExchanges", { url: "/exchange/" }))
      .catch((err) => err);
  },
  connectExchange: ({ commit }, { data }) => {
    repository
      .Create("/exchange/connect/", data)
      .then((res) => {
        commit("connectExchange");
        String(res["BTC"]).includes("e") ? (res["BTC"] = 0) : "";
        commit("setWallet", res);
        toast.success("Borsa hesabınıza giriş gerçekleştirildi");
      })
      .catch(() => {
        toast.error("Giriş gerçekleştirilemedi!");
      });
  },
  submitOrder: ({ commit }, { data }) => {
    repository
      .Create("/exchange/trade/", data)
      .then((res) => {
        commit("submitedOrder");
        String(res["BTC"]).includes("e") ? (res["BTC"] = 0) : "";
        commit("setWallet", res);
        toast.success("Siparişiniz başarıyla oluşturuldu");
      })
      .catch(() => {
        toast.error("İşlem için yeterli bakiyeniz bulunmuyor!");
      });
  },
  createPriceSignal: ({ commit }, { data }) => {
    repository
      .Create("/price-signal/", data)
      .then((res) => {
        commit("setPriceSignal", res);
        toast.success("Sinyal başarıyla oluşturuldu");
      })
      .catch((err) => {
        return err;
      });
  },
  getPriceSignals: ({ commit }, { url }) => {
    repository
      .getAll(url)
      .then((res) => {
        commit("getActivePriceSignals", res);
        commit("getDeactivePriceSignals", res);
      })
      .catch((err) => err);
  },
  deletePriceSignal: ({ dispatch }, { pk, exchange_name }) => {
    repository
      .Delete(`/price-signal/${pk}/`, { exchange_name })
      .then(() => dispatch("getPriceSignals", { url: "/price-signal/" }))
      .catch((err) => err);
  },
};

export default actions;
