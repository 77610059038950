import { useToast } from "vue-toastification";

const notify = useToast();

class toast {
  static async success(message) {
    return notify.success(message);
  }
  static async info(message) {
    return notify.info(message);
  }
  static async warning(message) {
    return notify.warning(message);
  }
  static async error(message) {
    return notify.error(message);
  }
}

export default toast;
